














import { Component, Vue } from 'vue-property-decorator'
import { IProject } from '@/api/landingpage/types/projects.types'
import { IPage, PageStatus } from '@/api/landingpage/types/pages.types'
import GrapejsEditor from './../../components/GrapejsEditor/index.vue'

import {
    updateProject,
    getProjectById,
} from '@/api/landingpage/projects'

import {
    updatePage,
    getPageById,
} from '@/api/landingpage/pages'
import { get } from 'lodash'
import { VsToastOptionsObjectInterface } from '@advision/vision/src/components/VsToast/types'
import { UserModule } from '@/store/modules/user'
@Component({
    name: 'Projects',
    components: {
        GrapejsEditor,
    },
})
export default class extends Vue {
    private project: IProject | null = null
    private page: IPage | null = null

    get pageId () {
        return this.$route.params.pageId
    }

    get hasShop () {
        return UserModule.user.configuration.rules.shop
    }

    mounted () {
        this.retrievePageAndProject(this.pageId)
    }

    private async retrievePageAndProject (pageId: string) {
        try {
            this.project = null
            const response = await getPageById(pageId)
            const page = response.data
            this.page = page
            const res = await getProjectById(page.projectId)
            this.project = res.data
        } catch (e) {
            this.$router.replace({ name: 'PagesCards' })
        }
    }

    private returnToHome (): void {
        this.$router.push({ name: 'PagesCards' })
    }

    private changePageName (e: any) {
        if (!this.page) return
        this.page.name = e
    }

    private changePage (e: any) {
        if (!this.page) return
        this.page = Object.assign(this.page, e)
    }

    private redirectToShopPrices () {
        if (this.hasShop) {
            this.$router.push({
                name: 'prices',
            })
        }
    }

    private async editPageAndProject (e: any, publish?: boolean) {
        if (!this.page || !this.project) {
            this.$root.$vsToast({
                heading: this.$t(`landingpages.CreatePage.errors.default`).toString(),
                timeout: 5000,
            })
            e.stopLoading()
            return
        }
        try {
            const pageData: any = {
                name: this.page.name,
            }
            if (publish && this.page.status === PageStatus.disabled) {
                pageData.status = PageStatus.enabled
            }
            await updatePage(pageData, this.page._id)
            await updateProject(e.project, this.project._id)

            this.$root.$vsToast({
                heading: this.$t('landingpages.projects.saveSuccess').toString(),
                timeout: 5000,
            })

            this.$router.push({
                name: 'EditPage',
                params: {
                    pageId: this.page._id,
                },
            })
        } catch (e) {
            const responseMessage = get(e, 'response.data.message', 'default')
            const toastOption: VsToastOptionsObjectInterface = {
                heading: this.$t(`landingpages.CreatePage.errors.${responseMessage}`).toString(),
                timeout: 5000,
            }

            if (this.hasShop && (responseMessage === 'PagesLimitReached' || responseMessage === 'PagesPublicReached')) {
                toastOption.primaryAction = this.$t(`landingpages.CreatePage.errors.linkAction`).toString()
                toastOption.primaryActionCallback = this.redirectToShopPrices
            }

            this.$root.$vsToast(toastOption)
        }
        e.stopLoading()
    }
}
